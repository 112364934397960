import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <Title text="Projects" mdxType="Title" />
    <ul>

      <li {...{
        "parentName": "ul"
      }}>{`Project 1 - Caterpillar - `}
        <a {...{
          "href": "https://github.com/charlotteresnick/caterpillar",
          "parentName": "li"
        }}>{`Github`}</a>
        {` | `}
        <a {...{
          "href": "https://charlotteresnick.github.io/caterpillar/",
          "parentName": "li"
        }}>{`Live Demo`}</a></li>


      <li {...{
        "parentName": "ul"
      }}>{`Project 2 - Drink Finder - `}
        <a {...{
          "href": "https://github.com/charlotteresnick/Drink-Finder",
          "parentName": "li"
        }}>{`Github`}</a>
        {` | `}
        <a {...{
          "href": "https://blooming-journey-07077.herokuapp.com/",
          "parentName": "li"
        }}>{`Live Demo`}</a></li>


      <li {...{
        "parentName": "ul"
      }}>{`Project 3 - Where To Watch - `}
        <a {...{
          "href": "https://github.com/charlotteresnick/where2watch",
          "parentName": "li"
        }}>{`Github`}</a>
        {` | `}
        <a {...{
          "href": "https://serene-plateau-44893.herokuapp.com/",
          "parentName": "li"
        }}>{`Live Demo`}</a></li>


      <li {...{
        "parentName": "ul"
      }}>{`Project 4 - Charlotte Resnick Yoga - `}
        <a {...{
          "href": "https://github.com/charlotteresnick/charlotte-resnick-yoga",
          "parentName": "li"
        }}>{`Github`}</a>
        {` | `}
        <a {...{
          "href": "https://charlotteresnickyoga.com/",
          "parentName": "li"
        }}>{`Live Site`}</a></li>

    </ul>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      