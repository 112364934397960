import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p><strong {...{
        "parentName": "p"
      }}>{`I'm Charlotte`}</strong>
      {` - Welcome to my website! Check out my blog for bootcamp musings, project woes, and Friday thoughts. Feel free to read a little about me `}
      <a {...{
        "href": "/about",
        "parentName": "p"
      }}>{`here`}</a>
      {`.`}</p>
    <p>{`As of Fall 2020, I've completed a full stack software engineering bootcamp at General Assembly and am `}
      <strong {...{
        "parentName": "p"
      }}>{`seeking full time opportunities as a full stack software engineer!`}</strong>
      {` Take a look at my `}
      <a {...{
        "href": "/resume",
        "parentName": "p"
      }}>{`resume`}</a>
      {`, `}
      <a {...{
        "href": "https://github.com/charlotteresnick",
        "parentName": "p"
      }}>{`Github`}</a>
      {`, `}
      <a {...{
        "href": "https://www.linkedin.com/in/charlotteresnick/",
        "parentName": "p"
      }}>{`LinkedIn`}</a>
      {`, and let's `}
      <a {...{
        "href": "mailto:charlotte.resnick@gmail.com",
        "parentName": "p"
      }}>{`talk`}</a>
      {`.`}</p>
  </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      